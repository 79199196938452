<template>
  <section id="about" class="section_container">
    <v-container>
      <default-home-section-title :title="titleSection" />
      <v-row>
        <v-col cols="12" align="start">
          <v-card color="white" class="" elevation="0">
            <v-card-text class="text-left">
              <!-- <v-card-text class="text-center"> -->
              <p class="text-h4 font-weight-medium" style="color: black; text-indent: 1cm;" align="justify">
                {{ text.toUpperCase() }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Статистика по организациям -->
        <!-- <v-col
          v-for="(item, index) in organizationItems"
          :key="index + item.text"
          class="d-flex"
          :cols="$vuetify.breakpoint.mdAndUp ? 4 : 12"
        >
          <v-card color="black" elevation="5" class="flex-grow-1">
            <v-card-title>
              <v-flex>
                <p
                  class="text-h1 font-weight-black text-center pa-0"
                  :class="{
                    'text-h1': $vuetify.breakpoint.mdAndUp,
                    'text-h2': $vuetify.breakpoint.smOnly,
                    'text-h3': $vuetify.breakpoint.xsOnly,
                  }"
                >
                  {{ item.value }}
                </p>
              </v-flex>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <p
                class="
                  font-weight-bold
                  text-uppercase
                  subtitle-2
                  pa-0
                  text-center
                "
                v-html="item.text"
              ></p>
            </v-card-text>
          </v-card>
        </v-col> -->
        <!-- Статистика по продукции -->

        <!-- по требованию Жанарыса 14.06.2022 -->
        <!-- <v-col cols="12">
          <default-home-section-title :title="oilTitle" />
        </v-col>
        <v-col
          v-for="(item, index) in productItems"
          :key="index + item.text"
          class="d-flex"
          :cols="$vuetify.breakpoint.mdAndUp ? 4 : 12"
        >
          <v-card color="black" elevation="5" class="flex-grow-1">
            <v-card-title>
              <v-flex>
                <p
                  class="text-h1 font-weight-black text-center pa-0"
                  :class="{
                    'text-h2': $vuetify.breakpoint.mdAndUp,
                    'text-h3': $vuetify.breakpoint.smOnly,
                    'text-h4': $vuetify.breakpoint.xsOnly
                  }"
                >
                  {{ item.value }}
                </p>
              </v-flex>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <p
                class="
                  font-weight-bold
                  text-uppercase
                  subtitle-1
                  pa-0
                  text-center
                "
                v-html="item.text"
              ></p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <div style="color: black" class="font-italic text-right">
            {{ informationUpdate[0] }}
            <br />
            {{ informationUpdate[1] }}
          </div>
        </v-col> -->
      </v-row>
    </v-container>
  </section>
</template>
<script>
export default {
  name: "DefaultHomeAbout",
  components: {
    DefaultHomeSectionTitle: () => import("@/views/Home/HomeSectionTitle.vue")
  },
  data() {
    return {
      img: require("@/assets/img/news-03.jpeg"),
      titleSection: "О проекте",
      oilTitle: `Оборот нефти за ${new Date().getFullYear()} год:`,
      informationUpdate: [
        "*информация по 30% организаций нефтегазовой отрасли Республики Казахстан",
        "**данные актуализируются раз в неделю"
      ], //"*информация обновляется по мере поступления данных",
      text: "Целью создания системы является обеспечение уполномоченного органа в области углеводородов эффективным инструментом контроля оборота сырой нефти и газового конденсата (ПОДГОТОВКИ, ТРАНСПОРТИРОВКИ, ХРАНЕНИЯ, ОТГРУЗКИ, РЕАЛИЗАЦИИ, ВВОЗА НА ТЕРРИТОРИЮ И ВЫВОЗА ЗА ПРЕДЕЛЫ ТЕРРИТОРИИ РЕСПУБЛИКИ КАЗАХСТАН).",
      organizationItems: [
        {
          text: "нефте<wbr/>добыв<wbr/>ающих организаций",
          value: "98"
        },
        {
          text: "нефте<wbr/>транспорт<wbr/>ных организаций",
          value: "4"
        },
        {
          text: "нефте<wbr/>пере<wbr/>рабатыв<wbr/>ающих заводов",
          value: "20"
        }
      ],
      productItems: [
        {
          text: "добыча нефти",
          value: "34 503 492 т."
        },
        {
          text: "переработка нефти",
          value: "5 077 639 т."
        },
        {
          text: "экспорт нефти",
          value: "24 526 404 т."
        }
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
.section_container {
  background-color: #fff;
  width: 70%;
  margin: 0 auto;
}

.section_link {
  color: white;
  text-decoration: none;
}

.section_link:hover {
  color: black;
}
</style>
